import { useEffect, useState } from 'react';
import { Button, InfoBox, Modal } from 'src/components';

import { ActionButton } from '../../styles';
import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { usePreviousValue } from 'src/hooks';
import { useBarecancel } from 'src/services';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';

function checkOneYearPlanWarn(subscription: SubscriptionProduct | null | undefined) {
  if (subscription == null) {
    return null;
  }
  const id = subscription.id;
  if (id === 'fff40f24-53f0-4ef3-b375-728bf9b285f5' || id === '569c8fcc-d492-4a7d-b02f-04645b3cdbdd') {
    return subscription.price / 100;
  }
  return null;
}

export const CancelSubscription: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { cancelSubscription } = useUserActions();
  const {
    cancelSubscriptionStatus: status,
    isCancellationPending,
    isTrialing,
    user,
  } = useUserState();
  const openBarecancel = useBarecancel((user as unknown as {stripeId: string}).stripeId);

  const productNotActive = user.subscription?.subscriptionProduct?.active !== true;

  const prevStatus = usePreviousValue(status);
  useEffect(() => {
    if (status === 'idle' && prevStatus === 'pending') {
      setShowDialog(false);
      // if (isCancellationPending) {
      //   openBarecancel();
      // }
    }
  }, [status, prevStatus, isCancellationPending, openBarecancel]);

  const oneYearWarnPrice = checkOneYearPlanWarn(user.subscription?.subscriptionProduct);
  let content: JSX.Element;
  if (isTrialing) {
    content = (
      <>
        <p>Czy na pewno chcesz anulować okres próbny swojej subskrypcji?</p>
        <p>Utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else if (oneYearWarnPrice != null) {
    content = (
      <>
        <p><strong>UWAGA: Obecnie korzystasz z abonamentu za {oneYearWarnPrice} zł, który nie jest już dostępny w naszej stałej ofercie. Aktualna cena rocznego abonamentu to 597 zł. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki nie przerwiesz subskrypcji, możesz cieszyć się  preferencyjną ceną. Podczas Black Friday roczny abonament będzie kosztował 399 zł, a więc nadal więcej niż Twój obecny plan.</strong></p>
        <p>Czy nadal chcesz anulować subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else if (productNotActive) {
    content = (
      <>
        <p><strong>UWAGA: Obecnie korzystasz z abonamentu, który nie jest już dostępny w naszej stałej ofercie. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki nie przerwiesz subskrypcji, możesz cieszyć się preferencyjną ceną. Sprawdź nasz aktualny cennik.</strong></p>
        <p>Czy nadal chcesz anulować subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  } else {
    content = (
      <>
        <p>Czy na pewno chcesz anulować swoją subskrypcję?</p>
        <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
      </>
    );
  }


  return (
    <>
      {!isCancellationPending && (
        <ActionButton onClick={() => setShowDialog(true)}>
          Anuluj subskrypcję
        </ActionButton>
      )}
      <Modal open={showDialog}>
        <Modal.Title>Potwierdź anulowanie subskrypcji</Modal.Title>
        <Modal.Content>
          {content}
          {status === 'error' && (
            <InfoBox
              mode="error"
              message="Anulowanie subskrypcji nie powiodło się."
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Powrót"
            onClick={() => setShowDialog(false)}
            variant="minimal"
            color="primary"
            disabled={status === 'pending'}
          />
          <Button
            label={(productNotActive || oneYearWarnPrice != null) ? 'Anuluj mimo wszystko' : 'Anuluj subskrypcję'}
            onClick={cancelSubscription}
            disabled={status === 'pending'}
            pending={status === 'pending'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
